import EDISupportForm from "./Forms/EDIForm/EDISupportForm";
import EDIConfirmation from "./Forms/EDIForm/EDIConfirmation";
import KansasProviderAppealForm from "./Forms/KansasForm/KansasProviderAppealForm";
import KansasProviderAppealConfirmation from "./Forms/KansasForm/KansasProviderAppealConfirmation";
import { KansasProviderConfirmation } from "./Forms/KansasForm/KansasProviderConfirmation";
import KansasProviderGrievanceForm from "./Forms/KansasForm/KansasProviderGrievanceForm";
import KansasProviderReferralConfirmation from "./Forms/KansasForm/KansasProviderReferralConfirmation";
import KansasProviderReferralForm from "./Forms/KansasForm/KansasReferalForm";
import KansasProviderGrievanceConfirmation from "./Forms/KansasForm/KansasProviderGrievanceConfirmation";
import UhcWestReportCode from "./Report/UhcWestReportCode";
import UhcWestReportCodeResults from "./Report/UhcWestReportResults";
import {
  Routes,
  Route,
  BrowserRouter,
  createHashRouter,
} from "react-router-dom";
export const routes = createHashRouter([
  { path: "/", element: <EDISupportForm /> },

  {
    path: "/ediSupport",
    element: <EDISupportForm />,
  },
  {
    path: "/ediConfirmation",
    element: <EDIConfirmation />,
  },
  {
    path: "/kansasConfirmation",
    element: <KansasProviderConfirmation />,
  },
  {
    path: "/kansasReferral",
    element: <KansasProviderReferralForm />,
  },
  {
    path: "/kansasReferralConfirmation",
    element: <KansasProviderReferralConfirmation />,
  },
  {
    path: "/kansasProviderGrievanceForm",
    element: <KansasProviderGrievanceForm />,
  },

  {
    path: "/kansasProviderGrievanceConfirmation",
    element: <KansasProviderGrievanceConfirmation />,
  },
  {
    path: "/kansasAppeal",
    element: <KansasProviderAppealForm />,
  },
  {
    path: "/kansasProviderAppealConfirmation",
    element: <KansasProviderAppealConfirmation />,
  },
  {
    path: "/uhcWestReportSearch",
    element: <UhcWestReportCode />,
  },
  {
    path: "/uhcWestReportResults",
    element: <UhcWestReportCodeResults />,
  },
]);

export const internalRoutes = createHashRouter([
  { path: "/", element: <EDISupportForm /> },
  {
    path: "/ediSupport",
    element: <EDISupportForm />,
  },
  {
    path: "/ediConfirmation",
    element: <EDIConfirmation />,
  },
  {
    path: "/kansasConfirmation",
    element: <KansasProviderConfirmation />,
  },
  {
    path: "/kansasReferral",
    element: <KansasProviderReferralForm />,
  },
  {
    path: "/kansasReferralConfirmation",
    element: <KansasProviderReferralConfirmation />,
  },
  {
    path: "/kansasProviderGrievanceForm",
    element: <KansasProviderGrievanceForm />,
  },

  {
    path: "/kansasProviderGrievanceConfirmation",
    element: <KansasProviderGrievanceConfirmation />,
  },
  {
    path: "/kansasAppeal",
    element: <KansasProviderAppealForm />,
  },
  {
    path: "/kansasProviderAppealConfirmation",
    element: <KansasProviderAppealConfirmation />,
  },
  {
    path: "/uhcWestReportSearch",
    element: <UhcWestReportCode />,
  },
  {
    path: "/uhcWestReportResults",
    element: <UhcWestReportCodeResults />,
  },
]);
export const FormsRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<EDISupportForm />} />
        <Route path="/ediConfirmation" element={<EDIConfirmation />} />
        <Route exact path="/ediSupport" element={<EDISupportForm />} />
        <Route
          exact
          path="/kansasReferral"
          element={<KansasProviderReferralForm />}
        />
        <Route
          path="/kansasReferralConfirmation"
          element={<KansasProviderReferralConfirmation />}
        />
        <Route
          exact
          path="/kansasAppeal"
          element={<KansasProviderAppealForm />}
        />
        <Route
          path="/kansasProviderAppealConfirmation"
          element={<KansasProviderConfirmation />}
        />
        <Route
          exact
          path="/kansasProviderGrievanceForm"
          element={<KansasProviderGrievanceForm />}
        />
        <Route
          path="/kansasProviderGrievanceConfimation"
          element={<KansasProviderGrievanceConfirmation />}
        />
        <Route
          exact
          path="/uhcWestReportSearch"
          element={<UhcWestReportCode />}
        />
        <Route
          exact
          path="/uhcWestReportResults"
          element={<UhcWestReportCodeResults />}
        />
      </Routes>
    </BrowserRouter>
  );
};
